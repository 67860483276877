import React, { useState } from "react";
import vymlogo from "../assets/vym1-PhotoRoom.png-PhotoRoom.png";
import globaltraininglogo from "../assets/global-PhotoRoom.png-PhotoRoom.png";
import "./style.css";
const { BD } = require("../../BD");

export default function Home() {
  const [id, setId] = useState();
  const [registro, setRegistro] = useState({});

  const handleBuscarClick = () => {
    // Aquí puedes utilizar el valor (codigoCertificado) para realizar la búsqueda por ID
    // Utilizar el id para buscar el registro en tu "base de datos"
    const registroEncontrado = BD.find(
      (registro) => registro.id === parseInt(id, 10)
    );
    // También puedes llamar a tu función de búsqueda aquí

    setRegistro(registroEncontrado || {});
  };

  const handleKeyDown = (event) => {
    // Verificar si la tecla presionada es "Enter"
    if (event.key === "Enter") {
      event.preventDefault();
      handleBuscarClick();
    }
  };
  const handleChange = (event) => {
    // Actualiza el estado con el valor ingresado por el usuario
    setId(event.target.value);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <img className="logo1" src={vymlogo} alt="Logo VyM" />
        <div className="container">
          <h4 className="responsive-text" style={{ color: "white" }}>
            VYM de Honduras-Global Training
          </h4>
        </div>
        <img
          className="logo2"
          src={globaltraininglogo}
          alt="Logo Global Training"
        />
      </div>
      <br />

      <form class="row g-3">
        <div class="col-auto">
          <label for="inputPassword2" class="visually-hidden">
            Password
          </label>
          <input
            type="text"
            class="form-control"
            id="inputPassword2"
            placeholder="codigo"
            value={id}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={handleBuscarClick}
          >
            Comprobar Certificado
          </button>
        </div>
      </form>

      <div>
        <div class="card  text-bg-light" style={{ textAlign: "left" }}>
          <h5 class="card-header">Datos de la Persona </h5>
          <div class="card-body ">
            <h6 class="card-title">Nombre: {registro.nombre}</h6>
            {registro.identidad ? <h6 class="card-title">N° de Identidad : {registro.identidad}</h6>:<></>}
            <h6 class="card-text">Empresa: {registro.empresa}</h6>
          </div>
        </div>
      </div>
      <br />
      <div class="card  text-bg-light" style={{ textAlign: "left" }}>
        <h5 class="card-header">Informacion del Certificado</h5>
        <div class="card-body">
          {registro.tipoCertificado ? <h6 class="card-title">Certificado: {registro.tipoCertificado}</h6> : <> </>}          
          <h6 class="card-title">Instructor: {registro.instructor}</h6>
          <h6 class="card-text">Realizado el: {registro.fechaCertificacion}</h6>
          <h6 class="card-text">Expira el: {registro.fechaExpiracion} </h6>
        </div>
      </div>
    </div>
  );
}
